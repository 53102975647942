import React from 'react';

import { Container, Navbar, Nav } from 'react-bootstrap';

const Header = () => {
    return <Navbar collapseOnSelect expand="sm" className="bg-light mb-5">
        <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                <Nav className="mr-auto">
                    <Nav.Link className="mx-2 text-uppercase" href="/">Home</Nav.Link>
                    <Nav.Link className="mx-2 text-uppercase" href="/wedding">The Wedding</Nav.Link>
                    <Nav.Link className="mx-2 text-uppercase" href="/directions">Getting There</Nav.Link>
                    <Nav.Link className="mx-2 text-uppercase" href="/registry">Registry</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar >;
};

export default Header;
