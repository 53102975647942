import React from 'react';

const Wedding = () => {
    return <div style={{ paddingTop: '5rem' }}>
        <h1 className="text-center display-1">The Wedding</h1>
        <img src="leaves.svg" height="32" width="32" className="d-block mx-auto mt-5" />
        <div className="row mt-5">
            <p className="col-6 text-end fw-bold fs-7">Location:</p>
            <p className="col-6 text-start fw-normal fs-7">
                <a href="https://www.villaandvineweddings.com" className="text-reset">Villa &amp; Vine</a>
                <br />1316 State Street
                <br />Santa Barbara, CA 93101
            </p>
        </div>
        <div className="row">
            <p className="col-6 text-end fw-bold fs-7">Date:</p>
            <p className="col-6 text-start fw-normal fs-7">Sunday, July 24, 2022</p>
        </div>
        <div className="row">
            <p className="col-6 text-end fw-bold fs-7">Time:</p>
            <p className="col-6 text-start fw-normal fs-7">5pm - 11pm</p>
        </div>
        <iframe width="100%" height="450" className="d-block mx-auto mt-2" style={{ border: 0 }} loading="lazy" allowFullScreen
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJRXEnkocU6YARpTGS3p1fdwU&key=AIzaSyABUf7ur8-HwrxEo7Dv0M0QaA4nnefN5ao" />
    </div >;
};

export default Wedding;
