import React from 'react';
import { Button } from 'react-bootstrap';

const Registry = () => {
    return <div style={{ paddingTop: '5rem' }}>
        <h1 className="text-center display-1">Registry</h1>
        <img src="leaves.svg" height="32" width="32" className="d-block mx-auto mt-5" />
        <p className="mt-5 text-center">Our wedding registry is available at Bed Bath &amp; Beyond.</p>
        <div className="text-center">
            <Button href="https://www.bedbathandbeyond.com/store/giftregistry/viewregistryguest/551343869?eventType=Wedding" variant="outline-dark" className="mx-auto">Check it out</Button>
        </div>
        <p className="mt-5 text-center">&nbsp;</p>
    </div>;
};

export default Registry;
