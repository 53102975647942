import React from 'react';

const Home = () => {
    return <div style={{ paddingTop: '5rem' }}>
        <h1 className="text-center display-1">Zac and Nina</h1>
        <img src="leaves.svg" height="32" width="32" className="d-block mx-auto mt-5" />
        <p className="mt-5 text-center" style={{ letterSpacing: '.6rem', fontWeight: 'normal' }}>July 24, 2022</p>
        <p className="mt-5 text-center">Please join us at</p>
        <p className="text-center">
            <a href="https://www.villaandvineweddings.com" className="text-reset">Villa &amp; Vine</a>
            <br />1316 State St
            <br />Santa Barbara, CA 93101
        </p>
    </div>;
};

export default Home;
