import React from 'react';

const Directions = () => {
    return <div style={{ paddingTop: '5rem' }}>
        <h1 className="text-center display-1">Getting There</h1>
        <img src="leaves.svg" height="32" width="32" className="d-block mx-auto mt-5" />
        <p className="mt-5 text-center">
            <a href="https://www.villaandvineweddings.com/" className="text-reset">
                Villa &amp; Vine
            </a> is located in the heart of downtown Santa Barbara's State Street.
        </p>
        <p className="mt-5 text-center">
            There is no on-site parking; however there are numerous public parking lots within
            walking distance, curbside parking on neighboring streets, and Uber/Lyft.
        </p>
        <iframe className="d-block mx-auto" width="100%" height="450" style={{ border: 0 }} loading="lazy" allowFullScreen
            src="https://www.google.com/maps/embed/v1/search?q=parking%20lot%20near%20Villa%20%26%20Vine%2C%20State%20Street%2C%20Santa%20Barbara%2C%20CA%2C%20USA&key=AIzaSyABUf7ur8-HwrxEo7Dv0M0QaA4nnefN5ao&zoom=16&center=34.4248213,-119.7062641"></iframe>
    </div >
};

export default Directions;
