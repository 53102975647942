import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Header';
import { Home, Registry, Wedding, Directions } from './pages';

const App = () => {
  return <>
    <Router>
      <Header />
      <Container className="col-sm-auto col-sm-8 bg-light p-5">
        <Routes>
          <Route path="/wedding" element={<Wedding />} />
          <Route path="/directions" element={<Directions />} />
          <Route path="/registry" element={<Registry />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Container>
    </Router>
  </>;
};

export default App;
